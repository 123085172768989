<template>
  <div>
    <b-card
      title="โปรโมชั่น"
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="เบอร์โทร / ยูส"
                label-for="mc-first-name"
              >
                <b-form-input
                  id="mc-first-name"
                  v-model="userInput"
                  placeholder="เบอร์โทร / ยูส"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group />
            </b-col>
            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="searchMember"
              >
                ค้นหา
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearSearch"
              >
                เคลียร์
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-card-body v-if="memberInfo" />
        <b-row v-if="memberInfo">
          <b-col md="4">
            <h5 class="text-capitalize mb-75">
              เบอร์โทร / ยูส
            </h5>
            <b-card-text>
              {{ memberInfo.phoneno }} / {{ memberInfo.username }}
            </b-card-text>
          </b-col>
          <b-col md="4">
            <h5 class="text-capitalize mb-75">
              ชื่อ-สกุล
            </h5>
            <b-card-text>
              {{ memberInfo.fname }} {{ memberInfo.lname }}
            </b-card-text>
          </b-col>
          <b-col md="4">
            <h5 class="text-capitalize mb-75">
              บัญชี
            </h5>
            <b-card-text>
              <img
                :src="getBankImage(memberInfo.bank_code)"
                style="width: 24px; margin-bottom:5px"
              > {{ memberInfo.bank_name }} - {{ memberInfo.bank_acc_no }}
            </b-card-text>
          </b-col>
          <b-col
            md="4"
            class="mt-10"
          >
            <h5 class="text-capitalize mb-75">
              เครดิตปัจจุบัน
            </h5>
            <b-card-text class="text-right">
              {{ memberInfo.wallet.credit }}
            </b-card-text>
          </b-col>
        </b-row>
        <!-- </div> -->
      </b-card-body>
    </b-card>
    <b-row v-if="memberInfo">
      <b-col md="6">
        <b-card
          title=""
        >
          <h4>ยอดเทิร์น</h4>
          <b-badge
            v-if="parseFloat(memberInfo.withdrawable_turn_amount) == 0 || parseFloat(memberInfo.withdrawable_turn_amount) < parseFloat(memberInfo.total_turn_amount)"
            variant="success"
          >
            ถอนเงินได้
          </b-badge>
          <b-badge
            v-if="parseFloat(memberInfo.withdrawable_turn_amount) > parseFloat(memberInfo.total_turn_amount)"
            variant="danger"
          >
            ติดเทิร์น
          </b-badge>
          <br><br>
          <h5>เทิร์นต้องทำ {{ numberWithCommas(memberInfo.withdrawable_turn_amount || 0) }}</h5>
          <h5>เทิร์นรวมปัจจุบัน {{ numberWithCommas(memberInfo.total_turn_amount || 0) }}</h5>
          <h5>เทิร์นต้องทำเพิ่มเพื่อถอน {{ parseFloat(memberInfo.withdrawable_turn_amount) > parseFloat(memberInfo.total_turn_amount) ? numberWithCommas(memberInfo.withdrawable_turn_amount - memberInfo.total_turn_amount || 0): '0.00' }}</h5>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            :variant="'outline-danger'"
            size="sm"
            @click="removeTurn"
          >
            ลบเทิร์นที่ต้องทำ
          </b-button>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card
          title=""
        >
          <h4>สถานะโปรโมชั่น</h4>
          <div>
            <b-badge
              v-if="memberInfo.get_promo === 'Y'"
              variant="success"
            >
              เลือกรับโปรโมชั่น
            </b-badge>
            <b-badge
              v-if="memberInfo.get_promo === 'N'"
              variant="danger"
            >
              เลือกไม่รับโปรโมชั่น
            </b-badge>{{ ' ' }}
            <b-badge
              v-if="memberInfo.promotionLimitations.filter(p => p.active == 1).length == 0"
              variant="success"
            >
              ไม่ติดเงื่อนไขโปรโมชั่น
            </b-badge>
            <b-badge
              v-if="memberInfo.promotionLimitations.filter(p => p.active == 1).length != 0"
              variant="danger"
            >
              ติดเงื่อนไขการรับโปรโมชั่น
            </b-badge>
          </div><br>
          <div v-if="memberInfo.promotionLimitations.filter(p => p.active == 1).length != 0">
            <h5>จากโปร; {{ promotions.filter(p => p.id === memberInfo.currentPromotionLimitation.promotion_id)[0].detail }}</h5>
            <h5>เทิร์นต้องทำ {{ numberWithCommas(memberInfo.withdrawable_turn_amount || 0) }} | เทิร์นต้องทำเพิ่มเพื่อถอน {{ memberInfo.withdrawable_turn_amount > memberInfo.total_turn_amount ? numberWithCommas(memberInfo.withdrawable_turn_amount - memberInfo.total_turn_amount || 0): '0.00' }}</h5>
            <h5>จำกัดถอนเงินสูงสุด {{ memberInfo.currentPromotionLimitation.withdraw_limit }}</h5>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :variant="'outline-danger'"
              size="sm"
              @click="removeLimitation"
            >
              ลบการติดเงื่อนไขโปรโมชั่น
            </b-button>
          </div>
          <div v-if="memberInfo.promotionLimitations.filter(p => p.active == 1).length == 0">
            รับโปร <b-form-checkbox
              v-model="memberInfo.getPro"
              class="mr-0 mt-50"
              name="is-rtl"
              switch
              inline
              @change="changeUserGetPromo"
            />
          </div>
          <br>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="memberInfo">
      <b-col
        v-for="promotionItem in promotions"
        :key="`id${promotionItem.id}`"
        md="6"
      >
        <b-card
          title=""
          :class="[promotionItem.active == 0 ? 'disabled-promo':'']"
        >
          <h4>{{ promotionItem.detail }}</h4>
          <!-- <b-badge variant="success" v-if="memberInfo.promo_new_member == 'OFF' || memberInfo.promo_new_member == 'ON'">ยังไม่รับโปร</b-badge>
          <b-badge variant="warning" v-if="memberInfo.promo_new_member == 'PAID'">อยู่ระหว่างรับโปร</b-badge>
          <b-badge variant="danger" v-if="memberInfo.promo_new_member == 'FINISHED'">สิ้นสุดโปรแล้ว</b-badge> -->
          <br><br>
          <h5>ยังไม่ได้รับ {{ parseFloat(memberInfo.promotionWalletGrouped[promotionItem.id].totalReceivable).toFixed(2) }} บาท / รับแล้ว {{ parseFloat(memberInfo.promotionWalletGrouped[promotionItem.id].totalReceived).toFixed(2) }} บาท</h5>
          <h5>รับโปรล่าสุดเมื่อ {{ formatDateBKK(memberInfo.promotionWalletGrouped[promotionItem.id].lastReceived || '-').replace('Invalid date', '-') }}</h5>
          <h5>คำนวนล่าสุด {{ formatDateBKK(memberInfo.promotionWalletGrouped[promotionItem.id].lastCalculated || '-').replace('Invalid date', '-') }}</h5>
          <b-button
            v-if="['ROLLING-L1-CHILD','ROLLING-L2-CHILD','ROLLING-L3-CHILD','CASHBACK-L1-CHILD','CASHBACK-L2-CHILD', 'CASHBACK-L3-CHILD'].indexOf(promotionItem.event_type) >= 0"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            style="margin-right: 10px"
            :variant="'outline-primary'"
            size="sm"
            @click="viewDownlineDetail(promotionItem.id, memberInfo.id)"
          >
            รายละเอียด
          </b-button>
          <b-button
            v-if="promotionItem.calculate_type == 'WALLET'"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            :variant="'outline-danger'"
            size="sm"
            :disabled="promotionItem.active == 0"
            @click="getPro(promotionItem.id)"
          >
            รับโปร
          </b-button>
          <b-button
            v-if="promotionItem.calculate_type == 'ONCE'"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            :variant="'outline-danger'"
            size="sm"
            :disabled="!getEligibleForReceivePro(promotionItem)"
            @click="getProManual(promotionItem)"
          >
            รับโปร
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-card
      v-if="memberInfo"
      title="โปรโมชั่น"
    >
      <b-table
        ref="receivedPromotionsTable"
        striped
        hover
        responsive
        class="position-relative"
        :busy.sync="isBusy"
        :per-page="tableQuery.page_size"
        :current-page="tableQuery.page"
        :items="fetchRecords"
        :fields="fields"
      >
        <template #cell(username)="data">
          {{ data.item.phoneno }}<br>
          {{ data.item.username }}
        </template>
        <template #cell(fullname)="data">
          {{ `${data.item.fname} ${data.item.lname}` }}
        </template>
        <template #cell(note)="data">
          {{ `[${data.item.platform}][${data.item.txtype}` }}] {{ `${data.item.note}` }}
        </template>
        <template #cell(amount)="data">
          <div style="text-align: right;">
            {{ `${numberWithCommas(data.item.amount)}` }}
          </div>
        </template>
        <template #cell(balance)="data">
          <div style="text-align: right;">
            {{ `${numberWithCommas(data.item.balance)}` }}
          </div>
        </template>
        <template #cell(get_promo)="data">
          <b-badge :variant="{'Y':'light-success', 'N':'light-primary'}[data.item.get_promo]">
            {{ {'Y':'รับโปร', 'N':'ไม่รับ'}[data.item.get_promo] }}
          </b-badge>
        </template>
        <template #cell(invited_by)="data">
          <b-badge
            v-if="!data.item.invited_by"
            :variant="'light-danger'"
          >
            ไม่มี
          </b-badge>
          <div v-if="data.item.invited_by">
            {{ data.item.refer_by.phoneno }}<br>
            {{ data.item.refer_by.fname }} {{ data.item.refer_by.lname }}
          </div>
        </template>
        <template #cell(status)="data">
          <div style="text-align:center;">
            <b-badge :variant="{'Y':'light-success', 'N':'light-danger'}[data.item.is_active]">
              {{ {'Y':'แอคทีฟ', 'N':'ระงับ'}[data.item.is_active] }}
            </b-badge><br>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :variant="{'Y':'outline-danger', 'N':'outline-success'}[data.item.is_active]"
              size="sm"
              @click="updateMemberStatus(data.item, data.item.is_active === 'Y' ? 'inactive' : 'active')"
            >
              {{ {'Y':'ระงับยูสนี้', 'N':'แอคทีฟยูสนี้'}[data.item.is_active] }}
            </b-button>
          </div>
        </template>
        <template #cell(afflink)="data">
          <div style="text-align:center;">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-info"
              size="sm"
              @click="copyReferralLink(data.item)"
            >
              <feather-icon
                icon="CopyIcon"
                class="mr-50"
              />
              ก๊อปลิ้งชวนเพื่อน
            </b-button>
          </div>
        </template>
        <template #cell(btn_credit)="data">
          <div style="text-align:center;">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-info"
              size="sm"
              @click="updateCredit(data.item)"
            >
              <feather-icon
                icon="RefreshCcwIcon"
                class="mr-50"
              />
              เครดิต
            </b-button>
          </div>
        </template>
        <template #cell(btn_detail)="data">
          <div style="text-align:center;">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-info"
              size="sm"
              @click="viewMember(data.item)"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              แก้ไขข้อมูล
            </b-button>
          </div>
        </template>

      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="tableQuery.page_size"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="tableQuery.page"
            :total-rows="totalRows"
            :per-page="tableQuery.page_size"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BCardBody, BRow, BCol, BForm, BCardText, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BCardText,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BFormCheckbox,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userInput: null,
      isBusy: false,
      pageOptions: [100, 200, 500, 1000],
      txtypes: [{ title: 'ทั้งหมด', value: '' }, { title: 'รายการฝาก', value: 'DEPOSIT' }, { title: 'รายการแจ้งถอน', value: 'WITHDRAW' }, { title: 'รายการคืนเงิน', value: 'REFUND' }, { title: 'โปรโมชั่น', value: 'PROMOTION' }, { title: 'วางเดิมพัน', value: 'BET' }, { title: 'เคลียร์บิล', value: 'BETWIN' }, { title: 'เดิมพันที่ถูกยกเลิก', value: 'BETCANCEL' }],
      platformOpts: [{ title: 'ทั้งหมด', value: '' }, { title: 'กระเป๋าเงิน', value: 'WALLET' }, { title: 'SA', value: 'SA' }, { title: 'SEXY', value: 'AE' }, { title: 'WM', value: 'WM' }, { title: 'DG', value: 'DG' }, { title: 'CQ9', value: 'CQ9' }, { title: 'JOKER', value: 'JOKER' }, { title: 'JDB', value: 'JDB' }, { title: 'MG', value: 'MG' }, { title: 'SBO', value: 'SBO' }, { title: 'HOTGRAPH', value: 'HOTGRAPH' }, { title: 'LOTTO', value: 'LOTTO' }],
      totalRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      tableQuery: {
        page: 1,
        page_size: 100,
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'created_at', label: 'วันที่' },
        { key: 'username', label: 'ยูส/เบอร์' },
        { key: 'note', label: 'รายละเอียด' },
        { key: 'amount', label: 'จำนวน' },
        // { key: 'balance', label: 'คงเหลือ' },
        // { key: 'ref2', label: 'อ้างอิง 2' },
        // { key: 'ref3', label: 'อ้างอิง 3' },
        // { key: 'platform', label: 'ระบบ' },
      ],
      /* eslint-disable global-require */
      items: [
      ],
      promotions: [],
      promotionEvents: [],
      userPromotions: [],
      memberInfo: null,
      modalDownline: {
        show: false,
      },
      /* eslint-disable global-require */
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.fetchPromotions()
    this.totalRows = this.items.length
    if (this.$route.params.user !== null && this.$route.params.user !== undefined) {
      this.userInput = this.$route.params.user
      this.searchMember()
    }
  },
  methods: {
    searchMember() {
      this.fetchRecords()
    },
    clearSearch() {
      this.userInput = null
      this.tableQuery = {
        page: 1,
        page_size: 100,
      }
      this.$refs.receivedPromotionsTable.refresh()
    },
    viewDownlineDetail(proId, userId) {
      this.modalDownline.show = true
    },
    async fetchPromotions() {
      try {
        const { data } = await this.$http.get('promotions')
        if (data.success) {
          this.promotionEvents = data.promotionEvents
          this.promotions = data.promotions.data
        }
        console.log('promotion', data)
      } catch (err) {
        console.log(err)
      }
    },
    async fetchRecords(ctx) {
      console.log('[fetchRecords]', ctx)

      let fieldName = 'phoneno'
      if (/[a-zA-Z]/.test(this.userInput)) {
        fieldName = 'username'
        this.tableQuery.username = this.userInput
        delete this.tableQuery.phoneno
      } else {
        this.tableQuery.phoneno = this.userInput
        delete this.tableQuery.username
      }

      this.tableQuery.type = 'PROMOTION'
      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (idx > 0) query += '&'
          // if (this.tableQuery[key].\\)
          //   query += `${key}=${encodeURIComponent(this.tableQuery[key].value)}`
          // else
          query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
      }

      if ((this.userInput === null || this.userInput === undefined)) {
        this.items = []
        return this.items
      }

      const { data: memberApiResult } = await this.$http.get(`users/user-info?with_deposits=N&${fieldName}=${this.userInput}&user=${this.userInput}&searchBy=${fieldName}&with_promotion_stat=Y`)
      this.memberInfo = { ...memberApiResult.user, ...memberApiResult.promotionsInfo }

      const promoWalletGroupByIDs = []
      this.memberInfo.promotionWalletGrouped = {}
      this.promotions.forEach(promoItem => {
        this.memberInfo.promotionWalletGrouped[promoItem.id] = this.getSumPromotionByWalletGroup(promoItem.id, promoItem.calculate_type)
      })

      if (this.memberInfo.promotionLimitations.filter(p => p.active === 1).length !== 0) {
        // eslint-disable-next-line prefer-destructuring
        this.memberInfo.currentPromotionLimitation = this.memberInfo.promotionLimitations.filter(p => p.active === 1)[0]
      } else {
        this.memberInfo.currentPromotionLimitation = null
      }

      this.memberInfo.getPro = this.memberInfo.get_promo === 'Y'

      console.log('memberInfo', this.memberInfo)

      const { data: { wallet_transactions: { data, meta } } } = await this.$http.get(`wallet-transactions?${fieldName}=${this.userInput}&mode=user-promotions`)
      this.items = data
      this.totalRows = meta.total

      return this.items
    },
    getSumPromotionByWalletGroup(promotionId, calType = 'WALLET') {
      if (this.memberInfo.promotionWalletGrouped[promotionId])
        return this.memberInfo.promotionWalletGrouped[promotionId]

      let totalReceivable = 0
      let totalReceived = 0
      let lastReceived = null
      let lastCalculated = null
      if (calType === 'WALLET') {
        if ((this.memberInfo.promotionWallets || []).filter(p => p.promotion_id === promotionId)) {
          const walletLists = (this.memberInfo.promotionWallets || []).filter(p => p.promotion_id === promotionId)

          walletLists.forEach(promoWallet => {
            totalReceivable += parseFloat(promoWallet.unreceive_amount || 0)
            totalReceived += parseFloat(promoWallet.received_amount || 0)
            if (moment(lastReceived || '2021-01-01 00:00:00').isBefore(moment(promoWallet.last_received)))
              lastReceived = promoWallet.last_received
            if (moment(lastCalculated || '2021-01-01 00:00:00').isBefore(moment(promoWallet.last_calculated)))
              lastCalculated = promoWallet.last_calculated
          })

          return {
            totalReceivable, totalReceived, lastReceived, lastCalculated,
          }
        }
      } else {
        const receivedLists = (this.memberInfo.promotionLogs || []).filter(p => p.promotion_id === promotionId)
        receivedLists.forEach(receivedLog => {
          totalReceived += parseFloat(receivedLog.amount)
          if (moment(lastReceived || '2021-01-01 00:00:00').isBefore(moment(receivedLog.received_date)))
            lastReceived = receivedLog.received_date
        })
        return {
          totalReceivable, totalReceived, lastReceived, lastCalculated,
        }
      }

      return {
        totalReceivable, totalReceived, lastReceived, lastCalculated,
      }
    },
    getEligibleForReceivePro(pro) {
      // console.log(pro)
      return pro.event_type === 'REGISTER'
    },
    async getProManual(pro) {
      // console.log(pro)
      if (pro.event_type === 'REGISTER')
        await this.getPro(pro.id)
    },
    async getPro(pro) {
      try {
        const { data } = await this.$http.post(`users/get-promotion/${pro}`, { userId: this.memberInfo.id, promotion: pro }, { validateStatus: x => x !== 401 })
        if (data.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'รับโปรสำเร็จ',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.fetchRecords()
          this.$refs.receivedPromotionsTable.refresh()
          return
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'รับโปรไม่สำเร็จ',
            text: data.error_message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'รับโปรไม่สำเร็จ',
            text: err,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async removeTurn() {
      try {
        const { data } = await this.$http.post('users/promotion/remove-turn', { userId: this.memberInfo.id })
        if (data.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'ลบเทิร์นสำเร็จ',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.fetchRecords()
          // return
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'ลบเทิร์นไม่สำเร็จ',
            text: err,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async removeLimitation() {
      try {
        const { data } = await this.$http.post('users/promotion/remove-limitation', { userId: this.memberInfo.id })
        if (data.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'ลบข้อจำกัดโปรโมชั่นสำเร็จ',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.fetchRecords()
          // return
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'ลบข้อจำกัดโปรโมชั่นไม่สำเร็จ',
            text: err,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async changeUserGetPromo() {
      try {
        const { data } = await this.$http.post('users/promotion/change-getpro', { userId: this.memberInfo.id, getPro: this.memberInfo.getPro })
        if (data.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'เปลี่ยนตัวเลือกการรับโปรสำเร็จ',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.fetchRecords()
          // return
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'เปลี่ยนตัวเลือกการรับโปรไม่สำเร็จ',
            text: err,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
<style lang="scss">
.mt-10 {
  margin-top: 20px;
}
.text-right {
  text-align: right;
}

.disabled-promo {
  filter: grayscale(100%);
}
</style>
